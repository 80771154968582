import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";

import Header from "../components/common/header";
import CommonFooter from "../components/common/footer";
import Content from "../components/article/content";
import ArticleSider from "../components/article/article-sider";

export default ({ data }) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>DayViewer Online Calendar, Planner Organizer - Articles</title>
      <meta
        name="description"
        content="DAYVIEWER - Productivity articles and the importance of online organizer, team calendar and business productivity software and solutions - some tips and articles and more interesting was to plan and manage your time with DayViewer Online Planner"
      />
    </Helmet>
    <Layout>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <ArticleSider />
        <Content />
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);
