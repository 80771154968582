import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import { Layout, List, Card } from "antd";
const { Content } = Layout;

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { published: { eq: true }, pages: { eq: "content" } }
            # fields: { slug: { regex: "/content-articles/.*/" } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "MMMM, YYYY")
                pages
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `}
    render={(data) => (
      <Content>
        <div className="content">
          {" "}
          <Card title="DayViewer Productivity Articles">
            <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id}>
                <List bordered>
                  <List.Item>
                    <Link to={node.fields.slug}>
                      <h3>
                        {node.frontmatter.title}{" "}
                        <span style={{ fontSize: 12 }}>
                          - {node.frontmatter.date}
                        </span>
                      </h3>
                      <span>{node.excerpt}</span>
                    </Link>
                  </List.Item>
                </List>
              </div>
            ))}
          </Card>
        </div>
      </Content>
    )}
  />
);
